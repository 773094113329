@use 'sass:map';
@use '@angular/material' as mat;

@import '@angular/material/theming';
@import '../custom-global-themed-classes';
@include mat.core();

$themed-components: (
    header: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 800)),
    headercolor: white,
    headerfontcolor: #6c757d,
    subheader: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 800)),
    subheadercolor: white,
    accent: #ddd,
    text: #5A6779,
    border: #e4e6e8,
    darkborder: #282C37,
    altaccent: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 200)),
    colorbox1: #03a9f4,
    colorbox2: #4caf50,
    colorbox3: #009688,
    colorbox4: #673ab7,
    jumphire_text: #f1556c
);

$primary: mat.define-palette(mat.$indigo-palette, 800);
$accent:  mat.define-palette(mat.$pink-palette, A400);
$warn:    mat.define-palette(mat.$yellow-palette, 700);
$theme: mat.define-light-theme($primary, $accent, $warn);
@include mat.all-component-themes($theme);
@include create-custom-classes($theme, $themed-components);

.light {
    $themed-components: (
        header: mat.get-color-from-palette(mat.define-palette(mat.$grey-palette, 900)),
        headercolor: white,
        headerfontcolor: #6c757d,
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$grey-palette, 500)),
        subheadercolor: 282C37,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 100)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$grey-palette, 400)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$grey-palette, 600)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$grey-palette, 800)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$grey-palette, 900)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$grey-palette, 900)),
        jumphire_text: #f1556c
    );

    $primary2: mat.define-palette(mat.$grey-palette, 900);
    $primary: mat.define-palette(mat.$blue-palette, 900);
    $accent:  mat.define-palette(mat.$grey-palette, 200);
    $warn:    mat.define-palette(mat.$red-palette, 500);
    $theme: mat.define-light-theme($primary, $accent, $warn);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);
}

.indigo-pink {
    $themed-components: (
        header: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette)),
        headercolor: #a0abbf,
        headerfontcolor: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 100)),
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette)),
        subheadercolor: white,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 100)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 900)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$pink-palette, A200, A100, A400)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 900)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$purple-palette, 900)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 900)),
        jumphire_text: #f1556c
    );

    $primary: mat.define-palette(mat.$indigo-palette);
    $accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);

    $theme: mat.define-light-theme($primary, $accent);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);
}

.dark {
    $themed-components: (
        header: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 900)),
        headercolor: #000,
        headerfontcolor: #F4F7FA,
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 900)),
        subheadercolor: white,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 100)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 900)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$blue-grey-palette, 800)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 900)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$purple-palette, 900)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 900)),
        jumphire_text: #f1556c
    );

    $primary: mat.define-palette(mat.$blue-grey-palette, 900);
    $accent:  mat.define-palette(mat.$blue-grey-palette, 300);
    $warn:    mat.define-palette(mat.$red-palette, 500);
    $theme: mat.define-light-theme($primary, $accent, $warn);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);
}


.matts {
    $themed-components: (
        header: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        headercolor: #efefef,
        headerfontcolor: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        subheadercolor: white,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$blue-palette, 200)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$blue-palette, 800)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$light-blue-palette, 300)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 900)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        jumphire_text: #f1556c
    );

    $primary: mat.define-palette(mat.$teal-palette, 300);
    $accent:  mat.define-palette(mat.$blue-palette, 800);
    $warn:    mat.define-palette(mat.$red-palette, 500);
    $theme: mat.define-light-theme($primary, $accent, $warn);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);

    .fixed-side-nav {
        background: #1b1a1a;

        .tab-label, .list-group.submenu-list {
            color: #bfbfbf;
        }
    }
}


.blue-and-teal {
    $themed-components: (
        header: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        headercolor: #fff,
        headerfontcolor: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        subheadercolor: white,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$blue-palette, 200)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$blue-palette, 800)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$light-blue-palette, 300)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 900)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 300)),
        jumphire_text: #f1556c
    );

    $primary: mat.define-palette(mat.$teal-palette, 300);
    $accent:  mat.define-palette(mat.$blue-palette, 800);
    $warn:    mat.define-palette(mat.$red-palette, 500);
    $theme: mat.define-light-theme($primary, $accent, $warn);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);
}

.pink-bluegrey {
    $themed-components: (
        header: #da0000,
        headercolor: #fff,
        headerfontcolor: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 500)),
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 500)),
        subheadercolor: white,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$blue-palette, 200)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 500)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$blue-palette, 800)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 500)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$light-blue-palette, A400)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$indigo-palette, 500)),
        jumphire_text: #f1556c
    );

    $primary: mat.define-palette(mat.$pink-palette, 700, 500, 900);
    $accent:  mat.define-palette(mat.$blue-grey-palette, A200, A100, A400);

    $theme: mat.define-light-theme($primary, $accent);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);
}

.candy {
    $themed-components: (
        header: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        headercolor: #f8bbd0,
        headerfontcolor: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        subheadercolor: white,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$pink-palette, 100)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$red-palette, 600)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$yellow-palette, 800)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$deep-orange-palette, 500)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        growl-warning: mat.get-color-from-palette(mat.define-palette(mat.$red-palette)),
        jumphire_text: #f1556c
    );

    $primary: mat.define-palette(mat.$deep-purple-palette);
    $accent:  mat.define-palette(mat.$pink-palette, A200, A100, A700);
    $warn:    mat.define-palette(mat.$red-palette);
    $theme: mat.define-light-theme($primary, $accent, $warn);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);
}


.bluecoral {
    $themed-components: (
        header: #89B0AE,
        headercolor: #89B0AE,
        headerfontcolor: #555B6E,
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        subheadercolor: white,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$cyan-palette, 100)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$red-palette, 600)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$yellow-palette, 800)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$deep-orange-palette, 500)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        growl-warning: mat.get-color-from-palette(mat.define-palette(mat.$red-palette)),
        jumphire_text: #f1556c
    );

    $primary: mat.define-palette(mat.$cyan-palette, 900);
    $accent: mat.define-palette(mat.$pink-palette, A200, A100, A700);
    $warn: mat.define-palette(mat.$red-palette);
    $theme: mat.define-light-theme($primary, $accent, $warn);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);
}


.fire {
    $themed-components: (
        header: #89B0AE,
        headercolor: #fff59d,
        headerfontcolor: #555B6E,
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        subheadercolor: white,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$yellow-palette, 200)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$red-palette, 600)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$yellow-palette, 800)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$deep-orange-palette, 500)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        growl-warning: mat.get-color-from-palette(mat.define-palette(mat.$red-palette)),
        jumphire_text: #f1556c
    );

    $primary: mat.define-palette(mat.$deep-orange-palette, 900);
    $accent: mat.define-palette(mat.$yellow-palette, 300);
    $warn: mat.define-palette(mat.$red-palette);
    $theme: mat.define-light-theme($primary, $accent, $warn);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);
}


.pistachio {
    $themed-components: (
        header: #89B0AE,
        headercolor: #91C498,
        headerfontcolor: #555B6E,
        subheader: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        subheadercolor: white,
        accent: #F4F7FA,
        text: #5A6779,
        border: #e4e6e8,
        darkborder: #282C37,
        altaccent: mat.get-color-from-palette(mat.define-palette(mat.$light-green-palette, 300)),
        colorbox1: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        colorbox2: mat.get-color-from-palette(mat.define-palette(mat.$red-palette, 600)),
        colorbox3: mat.get-color-from-palette(mat.define-palette(mat.$yellow-palette, 800)),
        colorbox4: mat.get-color-from-palette(mat.define-palette(mat.$deep-orange-palette, 500)),
        growl-info: mat.get-color-from-palette(mat.define-palette(mat.$deep-purple-palette)),
        growl-warning: mat.get-color-from-palette(mat.define-palette(mat.$red-palette)),
        jumphire_text: #f1556c
    );

    $primary: mat.define-palette(mat.$deep-orange-palette, 900);
    $accent: mat.define-palette(mat.$yellow-palette, 300);
    $warn: mat.define-palette(mat.$red-palette);
    $theme: mat.define-light-theme($primary, $accent, $warn);
    @include mat.all-component-themes($theme);
    @include create-custom-classes($theme, $themed-components);
}


// @import './light.scss';