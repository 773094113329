.emoji-picker-menu.mat-menu-panel {
    box-shadow: none;
    border: 0;
    background: transparent;
    padding: 0 6px;
    box-sizing: border-box;
    max-width: unset;
}

.emoji-icon.like i {
    font-size: 42px;
    color: #007bff;
}

.emoji-icon.love i {
    font-size: 42px;
    color: #dc3545;
}

.reaction {
    background-image: url('/assets/images/static_emojis.jpg');
    background-repeat: no-repeat;
    height: 23px;
    width: 23px;
    background-size: 500px;
    cursor: pointer;
    border-radius: 50%;
}

.reaction-icon {
    height: 23px;
    width: 23px;
    cursor: pointer;
    border-radius: 50%;    
    justify-content: center;
}

.reaction.angry {
    background-position: -18px -140px;
}

.reaction.nervous {
    background-position: -59px -172px;
}

.reaction.confused {
    background-position: -140px -108px;
}

.reaction.sad {
    background-position: -338px -172px;
}

.reaction.happy {
    background-position: -19px -46px;
}

.reaction.interested {
    background-position: -220px -78px;
}

.reaction.like {
    background-image: url('/assets/images/temp_like_icons.png');
    background-size: 41px;
    background-position: 1px 3px;
}

.reaction.cold {
    background-image: url('/assets/images/icons/icons8-cold-face-50.png');
    background-size: 22px;
}

.reaction.exploding {
    background-image: url('/assets/images/icons/icons8-exploding-head-50.png');
    background-size: 22px;
}

.reaction.love {
    background-image: url('/assets/images/temp_like_icons.png');
    background-size: 41px;
    background-position: -21px 3px;
}

.current-reaction {
    font-weight: 600;
    height: 32px;
}

.current-reaction .current-user-action {
    padding: 5px 0 5px 2px;
    box-sizing: border-box;
    height: 23px;
}

.current-reaction .social-btn-with-icon {
    color: rgb(247, 177, 37);
}

.current-reaction .social-btn-with-icon.love {
    color: #f54f65;
}

.current-reaction .social-btn-with-icon.like {
    color: #548dfc;
}

.current-reaction .social-btn-with-icon.cold {
    color: #266df9;
}

.current-reaction .social-btn-with-icon.exploding {
    color: #f97426;
}

.reply-actions .reaction-count {
    padding-left: 0 !important;
    padding-right: 5px;
    box-sizing: border-box;
}

.reply-actions .reactions {
    border-radius: 10px;
    padding: 2px 1px 2px 2px;
    font-size: 11px;
    color: #8d949e;
    background: #fff;
    box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, .2);
    box-sizing: border-box;
}

.label-only .social-btn-with-icon .icon {
    display: none;
}

.reply-actions button.current-reaction {
    background: transparent;
    padding: 0;
    min-width: 10px;
    font-size: 14px;
    box-sizing: border-box;
}

.reply-actions button.current-reaction .current-user-action {
    padding: 2px;
    box-sizing: border-box;
    height: 15px;
}

.top-header-author .author-thumb {
    border: 2px solid #e6ecf5 !important;
    width: 170px !important;
    height: 170px !important;
}

button.social-rounded-btn {
    border-radius: 20px;
    background: #f5f6f7;
    border: 1px solid hsla(0,0%,84.7%,.5803921568627451);
    color: #4b4f56;
    cursor: pointer;
}

button.social-rounded-btn:focus {
    outline: 0;
    box-shadow: 0 0 3pt 1pt #d8d8d88f;
}

button.social-rounded-btn {
    background: #f5f6f7;
    border: 1px solid #d8d8d894;
    color: #4b4f56;
    cursor: pointer;
}

button.social-rounded-btn:hover {
    background: #e4e4e4;
}

button .social-btn-with-icon,
a .social-btn-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button .social-btn-with-icon .icon {
    display: flex;
    padding-right: 5px;
    box-sizing: border-box;
    color: #673ab7e8;
}

button.social-rounded-btn.label-only .label,
.pink-link {
    font-size: 14px;
    cursor: pointer;
}

.pink-link {
    font-size: 11px;
    font-weight: 500;
}

.pink-link:hover {
    text-decoration: underline;
}

.like-wrapper button.social-rounded-btn.label-only .label,
.pink-link {
    color: #ca35e0;
    text-decoration-color: #ca35e0;
}

.like-wrapper button.social-rounded-btn.label-only:hover,
.pink-link:hover {
    text-decoration-color: #ca35e0;
}

button.social-rounded-btn.label-only:hover {
    text-decoration: underline;
}

button.social-rounded-btn.icon-only,
button.social-rounded-btn.label-only {
    background: transparent;
    border: none;
    box-shadow: none;
    min-width: 30px;
    padding: 0;
    box-sizing: border-box;
}

button.social-rounded-btn.icon-only:focus,
button.social-rounded-btn.label-only:focus {
    box-shadow: none;
}

button.social-rounded-btn.icon-only .social-btn-with-icon .icon {
    color: #616770b0;
}

button.social-rounded-btn.icon-only:hover .social-btn-with-icon .icon {
    color: #673ab7e8;
}

button.social-rounded-btn.label-only .social-btn-with-icon .icon {
    display: none;
}

.icon-only .label {
    display: none;
}

.conversation-wrapper button.social-rounded-btn {
    border-radius: 5px;
}

.conversation-wrapper .mat-form-field-flex {
    align-items: flex-start;
}

.social-btn-with-icon .mat-button-wrapper {
    display: flex;
}

.conversation-wrapper .mat-form-field-flex .mat-form-field-suffix {
    position: absolute;
    top: -10px;
    right: 0;
}

.conversation-wrapper .edit-conversation-input .mat-form-field-flex .mat-form-field-suffix {
    top: 0 !important;
}

.conversation-wrapper .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
}

.hidden-select .mat-form-field-label-wrapper {
    display: none;
}

.hidden-select .mat-form-field {
    position: absolute;
    top: -25px;
    z-index: 1;
}

.hidden-select .mat-select-trigger {
    opacity: 0;
    margin-top: -15px;
}

.social-category-picker.mat-select-panel {
    margin-top: 25px;
}