/*
@media only screen and (max-width: 650px) {
    body {
        background-color: lightblue;
    }
}


@media only screen and (max-width: 400px) {
    body {
        background-color: pink;
    }
}
*/

/* Start with MOBILE */

.field-picker-outer-wrapper {
    .node-outer-wrapper {
        width: 100%;
        border: 1px solid transparent;
        border-radius: 5px;

        .node-wrapper {
            display: flex;
            align-items: center;

            .relate-label {
                cursor: pointer;
                font-size: 0.9rem;
            }
        }

        &:hover {
            border-color: #cbcbcbd6;
        }

        &.active-module {
            border-color: #707070;
        }
    }
}

.cover-profile .profile-contents-wrapper {
    width: 100%;
    display: flex;
    flex: auto;
    justify-content: space-between;
}

.cover-profile .profile-contents-wrapper .media-body {
    max-width: 350px;
}

.cover-profile .profile-contents-wrapper .profile-btn-box.pull-right.cover-btn {
    position: absolute;
}

.cover-profile .profile-contents-wrapper .profile-btn-box {
    margin-top: 30px;
}

.smart-table-wrapper, .mat-table-wrapper {
    overflow: scroll;
}

.btn.pull-right {
    width: 140px !important;
}


.dw-table td.mat-cell:first-child, td.mat-footer-cell:first-child, th.mat-header-cell:first-child {
    padding-left: 20px !important;
}
.dw-table td.mat-cell:last-child, td.mat-footer-cell:last-child, th.mat-header-cell:last-child {
    padding-right: 20px !important;
}

#page-wrap-inner > .col-md-12 {
    padding-left: 0;
    padding-right: 0;
}

.user-title h2 {
    font-size: 13px;
    margin: 3px 0;
}

.card-block.user-info {
    top: -18px !important;
}

.profile-bg-img {
    min-height: 100px;
}

.cover-btn {
    bottom: 0;
    right: 15px;
}

.user-info .media-body {
    padding: 7px;
}

.user-info .media-body, .user-info .media-left {
    display: block;
    width: 165px;
}

.pull-right .btn {
    margin-right: 0 !important;
}

.cover-profile .btn-primary {
    padding: 5px 10px !important;
    font-size: 14px;
}

.cover-profile .btn-primary .fa {
    font: normal normal normal 15px/1 FontAwesome !important;
}


.styled-loader {
    position: relative;
    min-height: 400px;

    .loading-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: #cbcbcb;
      
        .loading-with-info {
            width: 375px;
            height: 300px;
            margin: 0 auto;
            background: #fff;
            display: flex;
            flex-direction: column;
      
            .loading-label {
                font-size: 1rem;
                font-weight: bold;
            }
      
            .spin-wrap {
              margin: 35px 0;
            }
      
            .processing-details {
              text-align: center;
              padding: 0 10px;
              color: #8e8ceb;
              font-weight: bold;
            }
      
            .current-results {
              padding: 25px 0 0px;
      
              .label {
                padding-bottom: 5px;
              }
            }
        }
      }
}


.card-heading.flexed {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-col {
        display: flex;
        align-items: center;        
    }
}

.mobile-hidden {
    display: none;
}

.flex-field {
    flex: 100%;
    max-width: 100%;
}

.flex-wrapper .column {
    flex: 100%;
}

.flex-field.flex-force-full {
    flex: 100% !important;
}

.full-width .flex-grid.space-between .flex-field {
    // flex: 0 0 calc(50% - .5em);
}


.full-width .flex-grid.space-between .flex-field.full-field-flex,
.card .flex-field.full-field-flex {
  flex-basis: 100%;
  flex: 100%;
  max-width: 100%;
}

.flex-grid.space-between {
    justify-content: space-between;
}

.header-menu-trigger {
    cursor: pointer;
}

.header-menu-trigger:hover {
    color: #7774e7;
    font-weight: bold;
}


.full-width-btn-group {
    margin-top: 25px;
    padding-left: 15px;

    button {
        margin-right: 10px;
    }
}

.two-button-full-width-flex {
    width: 100%;
    justify-content: space-between;
}

.row {
    display: flex;

    .col-md-12 {
        width: 100%;
    }

    .col-md-6 {
        width: 49%;
    }

    .col-md-4 {
        width: 32%;
    }     

    .col-md-3 {
        width: 24%;
    }   
    
    .col-md-6 {
        margin-right: 5px;
    }
}

@media (max-width: 679px) {
    .row {
        .col-sm-12 {
            width: 100% !important;
        }
    }
}

.borderless-tabs.info-tabs {
    .flex-grid {
        display: block;

        .flex-field {
            max-width: 100%;
        }
    }
}

.new-profile .mobile-picker {
    display: block;
    background: #fff;
    padding: 10px 0 0 15px;
    margin-bottom: 5px;

    .mat-form-field {
        width: 96%;
    }
}

.control-block {
    width: auto;
    display: flex;
}


.grid-field-switch.hide-labels {
    .flex-wrapper {
        border-top: 0;
    }

    .flex-wrapper.form-field {
        border-top: 0 !important;
    }

    .column.title {
        display: none;
    }
}


@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
    .control-block {
        width: auto;
        display: flex;
    }  
}


@media (min-width: 680px) {
    .cover-profile .profile-contents-wrapper .profile-btn-box {
        margin-top: 0;
    }

    .flex-field.full-width {
        flex: 100%;
        max-width: 100%;
    }

    .flex-field {
        flex: 49%;
        max-width: 49%;
    }

    // .flex-wrapper .column {
    //     flex: 49%;
    // }  

    .flex-wrapper .title.column {
        flex: 24%;
    }  
    .flex-wrapper .field.column {
        flex: 75%;
    }  
}

@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    /* Begin normal layout here (non-mobile) */  

    .profile-bg-img {
        min-height: 219px;
    }

    .smart-table-wrapper, .mat-table-wrapper {
        overflow: hidden;
    }

    .cover-profile .btn-primary {
        padding: 10px 20px !important;
        font-size: 13px;
    }    

    .cover-profile .btn-primary .fa {
        font: normal normal normal 25px/1 FontAwesome !important;
        top: 3px;
        position: relative;
    }    

    .user-info .media-body, .user-info .media-left {
        display: table-cell;
        width: auto;
    }    

    .user-info .media-body {
        padding: 20px;
    }

    .pull-right .btn {
        margin-right: 10px !important;
    }    

    .cover-btn {
        right: 36px;
    }    

    .user-title {
        padding: 10px 0;
        
        h2 {
            font-size: 22px;
        }
    } 
    
    .card-block.user-info {
        bottom: 10px !important;
        top: unset !important;
    }      

    .dw-table td.mat-cell:first-child, td.mat-footer-cell:first-child, th.mat-header-cell:first-child {
        padding-left: 0 !important;
    }
    .dw-table td.mat-cell:last-child, td.mat-footer-cell:last-child, th.mat-header-cell:last-child {
        padding-right: 0 !important;
    }    
 
    .sidebar-toggle-container {
        display: none;
    }   
    
    .fixed-side-nav {
        min-width: 200px;
        position: fixed !important;
    }   
    
    .site-wrapper.main-container {
        display: flex;
        position: relative;
        top: 0;   
        z-index: 5000;   

        /* padding-left: 210px;   */
    }   

    .smart-table-wrapper {
        overflow: hidden;
    }
    
}


@media (min-width:1200px)  { 
    /* Full screen layout */  

    .new-profile .mobile-picker {
        display: none;
    }

}