a.mapboxgl-ctrl-logo {
    display: none;
    background-image: none;
}
.mapbox-directions-instructions, .mapboxgl-ctrl-attrib.mapboxgl-compact {
    display: none;
}

.directions-control.directions-control-directions {
    margin: 0;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    float: none;
}

.mapboxgl-marker {
    cursor: pointer;
}

.mapbox-directions-route-summary {
    background-color: #63c586 !important;

    h1 {
        font-weight: 700 !important;
    }

    span {
        color: rgb(255 255 255) !important;
        font-weight: 700;
    }
}

.open-close-toggle {
    justify-content: space-between;
    align-items: center;
}

.marker-key {
    margin: 5px 0;
    align-items: center;

    .marker-wrapper {
        padding: 5px 10px;
        border: 1px solid black;
        border-radius: 10px;
        display: flex;
        align-items: center;
        margin: 0 5px;
        font-size: 0.8rem;
    }

    .marker {
        height: 25px;
        width: 25px;
    }
}

.person-marker {
    background-image: url('https://yeehro.com/assets/images/icons/icons8-account-50.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;  
}

.marker {
    // background-image: url('https://www.pinpng.com/pngs/m/298-2987475_location-icon-gif-transparent-png-download-map-marker.png');
    background-image: url('https://yeehro.com/assets/images/icons/icons8-map-pin-50.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.orange-marker {
    background-image: url('/assets/images/icons/icons8-map-pin-50-orange.png');
}

.purple-marker {
    background-image: url('/assets/images/icons/icons8-map-pin-50-purple.png');
}

.green-marker {
    background-image: url('/assets/images/icons/icons8-map-pin-50-green.png');
}

.blue-marker {
    background-image: url('/assets/images/icons/icons8-map-pin-50-blue.png');
}


.popup-wrapper {
    background: #f7f7f7;
    padding: 15px;
    min-width: 150px;
    border: 1px solid #969696;
    border-radius: 5px;

    .main-info {
        position: relative;
        padding: 5px 0;

        .close-icon {
            position: absolute;
            top: -10px;
            right: -5px;
        }

        .info-title {
            font-weight: 600;
            padding-bottom: 10px;
        }

        .dates {
            padding-top: 10px;
        }

        .center {
            text-align: center;
        }
    }

    .default-flex {
        justify-content: space-around;

        .action-icon {
            display: flex; 
            align-items: center;
            justify-content: center;

            border: 1px solid transparent;
            border-radius: 5px;
            padding: 5px;

            img {
                height: 20px;
                width: 20px;
            }

            &:hover {
                border: 1px solid #969696;
            }
        }
    }
}