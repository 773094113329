.new-profile {
  min-height: calc(100vh - 110px);
  overflow-x: hidden;
  background: #fff;

  .flexed-wrapper {
    display: flex;
    // flex: 1 2 auto;
  }
}

button.square-icon-btn {
  padding: 0 10px;
}


.preview-panel {
  position: absolute;
  background: #f9f9f9;
  height: unset !important;
  box-shadow: -5px 0px 5px -3px #e3e1e3; -webkit-box-shadow: -5px 0px 5px -3px #e3e1e3;

  transition-property: right;
  transition-duration: 0.4s; -webkit-transition-duration: 0.4s; -moz-transition-duration: 0.4s; -o-transition-duration: 0.4s;
  transition-delay: .0s, .3s, 0s;
  transition-timing-function: ease-out;
  max-width: 850px;
  width: 70%;
  min-height: 100%;
  z-index: 9999;

  &.make-fixed-panel {
    position: fixed;
    height: calc(100vh - 70px) !important;
    min-height: calc(100vh - 70px);
    // overflow: hidden;
    overflow-y: auto;
  }
}

.preview-panel.preview-panel-auto-width {
  width: unset;
}

.preview-panel.closed-preview-panel {
  right: -1000px;
  top: 0;
}        

.preview-panel.open-preview-panel {
  right: 0;
  top: 0;
}

.preview-panel.make-fixed-panel.closed-preview-panel {
  right: -100%;
  top: 70px;
}        

.preview-panel.make-fixed-panel.open-preview-panel {
  right: 0;
  top: 70px;
}


.profile-header {
  display: flex;
  flex-direction: column;

  padding: 10px 30px;
  background: #fff;

  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;

  .profile-title-box {
    color: #494850;
    padding-bottom: 10px;
  }

  .profile-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .square-icon {
      margin-right: 20px;
    }

    .toggle-inner {
        padding: 0 10px;
    }
    .other-action-trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid black;
        border-radius: 5px;
    }
  }
}


.sidenav {
  display: none;
  opacity: 0;
  width: 350px;
}

.sidenav.active-sidenav, .sidenav.pinned-sidenav {
  display: block;
  opacity: 100;
}


.active-sidenav {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.inactive-sidenav {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}



.sub-sidebar {
  /*
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  box-shadow: 0 1px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  */
  border-radius: 7px;
  min-width: 200px;
  padding: 25px;
  display: none;

  .card-header {
    padding: 20px;
    background-color: #fff;
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #fff;
  }
}

.full-info-wrapper .info-tab-contents {
  display: flex;
  flex-wrap: wrap;

  .field-row {
    flex: 0 50%;
    min-width: 50%;
  }
}

.mobile-picker {
  display: block;
  background: #fff;
  padding: 10px 0 0 15px;
  margin-bottom: 5px;
}

.new-profile .sub-sidebar .card, .sub-sidebar .card .card-body {
  margin-bottom: 0;
  padding: 0;
}

.profile-full-info {
  margin-top: 5px;
}

.new-profile .nav .nav-item {
  // border-top: 1px solid rgba(0,0,0,.12);
}

.new-profile .nav .nav-item {
  display: flex;
  align-items: center;
  border-radius: .42rem;
  font-size: 0.75rem;
  padding: 3px 1.15rem;
  cursor: pointer;
  margin-bottom: 0.25rem;
  max-height: 35px;

  .nav-icon {
    flex: 0 0 2rem;
    max-width: 25px;
    margin-right: .5rem;

    .material-icons {
      font-size: 20px;
      color: #b5b5c3;
    }
  }

  .nav-link {
    display: inline-flex;
    align-items: center;
    position: relative;
    color: #3f4254cf;
    font-weight: bold;
    // background-color: #fff;
  }
}

.new-profile .nav-item .nav-link {
  padding: 15px 0;
}

.new-profile .main-content {
  flex: 1 1 auto;
  padding-left: 0;
  margin-top: 0;
}

.new-profile .nav-link {
  cursor: pointer;
}


.new-profile .flexed-wrapper {
  display: flex;
  flex-direction: column;

  .side-panel-right {
    order: 3;
  }
}


.preview-panel .preview-panel-wrapper {
  .new-profile {
    .profile-container > .flexed-wrapper {
      display: block;
      max-width: 570px;

      .full-info-wrapper {
        margin-top: 10px;
      }

      .flex-field {
        max-width: 100% !important;
      }
    }

    .edit-details-wrapper {
      .flex-field {
        max-width: 100% !important;
      }
    }
  }
}


@media (min-width:610px)  {
  /* Normal layout (non-mobile) */  
  .new-profile .colored-header {
    display: flex;
  }

  .profile-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .profile-button-container {
      justify-content: unset;
    }

    .profile-title-box {
      padding-bottom: 0;
    }
  }

  .new-profile .flexed-wrapper {
    flex-direction: row;

    .side-panel-right {
      order: unset;
    }
  }
}




@media (min-width:950px)  { 
  /* Full screen layout */  

  .new-profile .flexed-wrapper {
    display: flex;
    // width: 100%;
    // width: calc(100vw - 160px);
    width: calc(100vw - 90px);
  }

  .profile-full-info {
    margin-top: -5px;
  }

  .new-profile .nav {
      flex-direction: column;
  }

  .new-profile .profile-container {
      display: flex;
      padding-top: 10px;
  }

  .preview-panel-wrapper .new-profile .profile-container {
    display: block;
  }

  .new-profile .main-content {
      flex: 1 1 auto;
      padding: 0 10px;
      width: calc(100% - 350px);
  }

  .new-profile .mobile-picker {
      display: none;
  }
  .new-profile .sub-sidebar {
      padding: 0;
      display: block;
  }

}




@keyframes slide-in {
  100% { 
    transform: translateX(0%); 
  }
}

@-webkit-keyframes slide-in {
  100% { 
    -webkit-transform: translateX(0%); 
  }
}
  
@keyframes slide-out {
  0% { 
    transform: translateX(0%); 
  }
  100% { 
    transform: translateX(-100%); 
  }
}

@-webkit-keyframes slide-out {
  0% { 
    -webkit-transform: translateX(0%); 
  }
  100% { 
    -webkit-transform: translateX(-100%); 
  }
}



.fade-in {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}


.fade-out {
  animation: fadeOut ease 10s;
  -webkit-animation: fadeOut ease 10s;
  -moz-animation: fadeOut ease 10s;
  -o-animation: fadeOut ease 10s;
  -ms-animation: fadeOut ease 10s;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}