@import "./variables.scss";

.sidebar-container {
    margin-top: 65px;
}

.menu-link {
    height: 25px;

    i.material-icons {
        flex: 0 0 40px;
    }

    img.img-icon {
        padding-right: 15px;
        height: 25px;
    }
}

.abbr-wrapper {
    padding: 5px;
    text-align: center;
    flex: 0 0 30px;
    border: 1px solid #b0bec5;
    border-radius: 3px;

    .abbr-icon {        
        margin-top: -2px;
        font-size: 16px;
        color: #efefef;
        line-height: 16px;
    }  
}

.fixed-side-nav.on .menu-link-icon.uil {
    margin-right: 16px;
}

.abbr-wrapper:hover {
    border-color: $navPrimary;    

    * {
        color: $navPrimary;
    }
}


// .simplebar-scrollbar::before { opacity: 0.5 };

.menu-items-nav ul.menu-nav li.menu-item a.menu-link:hover {
    .abbr-wrapper {
        border-color: $navPrimary;
    }
    * {
        color: $navPrimary;
    }
}

.menu-items-nav {
    ul.menu-nav {
        list-style: none;
        margin: 0;
        padding: 15px 0;

        li.menu-item {
            position: relative;
            margin: 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-grow: 1;
            padding: 0;

            .menu-link {
                width: 100%;
                display: flex;
                padding: 15px 0;
                font-size: 13px;
                cursor: pointer;
                text-decoration: none;
                margin: 0;
                align-items: center;
                justify-content: center;

                i.menu-link-icon {
                    flex: 0 0 40px;
                }

                span.menu-link-text {
                    font-weight: 400;
                    font-size: 0.8rem;
                    flex-grow: 1;
                }
            }

            a.menu-link:hover {
                i.menu-link-icon,
                .menu-link-icon,
                span.menu-link-text {
                    color: #5d78ff !important;
                }
            }
        }

        li.menu-section {
            margin: 20px 0 0 0;
            height: 40px;
            display: flex;
            padding: 0 20px;

            h4.menu-section-text {
                color: #577fc0;
                font-size: .83rem;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: .3px;
            }
        }
    }
}

.main-sidebar {
    min-width: 150px;
    font-size: 13px;

    h2.list-header {
        margin-top: 20px;
        margin-bottom: 5px;
        color: #7b7b7b;
        text-transform: uppercase;
        font-size: 15px;
        padding-left: 18px;
    }

    .fa {
        font: 20px/1 FontAwesome !important;
    }

    .side-nav-logo-wrapper {
        margin-top: 25px;
        margin-bottom: 25px;

        .side-nav-logo {
            height: 45px;
            background-image: url(/assets/images/silk_logo_only.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

.sidebar-toggle-container {
    display: block;
    position: fixed;
    top: 10px;
}


@media (min-width:961px) {
    .sidebar-toggle-container {
        display: none;
    }
}

.nav.inner-nav {
    margin-left: -75px;
}

.main-container.full-with-left {
    padding-left: 70px;
    // width: calc(100vw - 75px);
    width: calc(100vw - 375px);

    // width: calc(100vw - 100px); // calc(100% - 80px);
}

.sidebar-container {
    z-index: 9999;
}

.pinned-sidebar .nav.inner-nav {
    margin-left: -200px;
}

.side-nav-logo-wrapper {
    width: 100%;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .silk-label {
        color: #577fc0;
        font-weight: 500;
        font-size: 1.4rem;
        padding-left: 5px;
    }
}

.side-nav-logo {
    background-image: url("/assets/images/logo-sm.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 25px;
    width: 25px;
    margin: 0 auto;
}

.fixed-side-nav {
    height: calc(100% - 65px);
    margin-top: 1px;
    // border: 1px solid #e6ecf5;
    background: #fff;
    background-color: #37474fed;
    position: fixed;

    .simplebar-content {
        margin: 0 0 75px 0 !important;
    }

    .menu-items-nav {
        height: 100%;
    }
    
    .dashboard-link-wrapper {
        .toggle {
            display: none;
        }
    }
}

.fixed-side-nav.pinned {
    overflow-y: auto;
}

.fixed-side-nav.on {
    width: 200px;

    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;

    .menu-items-nav {
        
        ul.menu-nav {
            padding: 15px;
        }
    }

    .menu-items-nav ul.menu-nav li.menu-item {
        align-items: flex-start;
    }

    .abbr-wrapper, .menu-link-icon {
        margin-right: 7px;
    }

    .dashboard-link-wrapper {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
    
        .dashboard-link {
            width: 75% !important;
        }
        
        .toggle {
            display: block;
            width: 25%;
        }
    }
}

.fixed-side-nav.off {
    width: 70px;
    min-width: 70px;

    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
}



.fixed-side-nav.on .side-nav-logo,
.fixed-side-nav.pinned .side-nav-logo {
    background-image: url("/assets/images/silk_logo_only.png");
}

.fixed-side-nav.off .menu-link-text {
    display: none;
}

.fixed-side-nav .sidebar-toggler {
    padding-left: 5px;
}

.fixed-side-nav.pinned .sidebar-toggler {
    display: flex;
}

.fixed-side-nav.pinned {
    position: fixed;
    width: 200px;
}

.fixed-side-nav.pinned .menu-link-text {
    display: inline-block;
}

.sidebar-toggle-container {
    display: none;
    position: fixed;
    top: 60px;
    left: -2px;
    background: #fff;
    border: 1px solid #0000001c;
    border-radius: 5px;
    width: 50px;

    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

#sidebar .sidebar-toggle-container span.icon-holder {
    padding: 5px 0 0 10px;
}

#sidebar .sidebar-toggle-container span.icon-holder .material-icons {
    background: #fff;
}

#sidebar .fixed-side-nav.off #bd-docs-nav {
    display: block;
}

.list-group-item.active {
    background-color: #dedfe0;
}

.bd-sidebar {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    order: 0;
}

.bd-links {
    width: 100%;
    min-width: 200px;
    overflow: hidden;
}

.bd-links .list-group-item {
    padding: 9px 15px;
}

.list-group-item-action {
    color: #888da8;
}

.left-nav-icon {
    cursor: pointer;
    padding: 15px;
    position: relative;
    left: 6px;
    top: 8px;
}

.list-group {
    font-size: 14px;
}

.list-group-item {
    display: block;
    position: relative;
}

.menu-toggler {
    cursor: pointer;
}

.menu-link-text {
    font-size: 14px;
}

.sidebar-toggle-container {
    cursor: pointer;
}

span.icon-holder {
    display: inline-block;
    height: 30px;
    width: 25px;
    cursor: pointer;
    line-height: 25px;
    text-align: center;
    position: relative;
    left: 0;
    margin-right: 14px;
    font-size: 17px;
    border-radius: 6px;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

span.subnav-icon {
    display: inline-block;
    position: relative;
    top: 7px;
    left: -3px;
}

.submenu-list a.list-group-item {
    /* padding-left: 65px; */
}


span.icon-holder .material-icons {
    font-size: 30px;
}

.closed {
    width: 35px;
    display: block;
}

#bd-docs-nav .list-group-item {
    border: 0 !important;
}

.side-nav-logo-wrapper {
    padding: 15px;
}


a.list-group-item.is-active {
    background-color: #9997e4;
    color: #fff;
}


.pinned-sidebar .nav.inner-nav {
    margin-left: 0;
}

.menu-link.home-link {
    img.img-icon {
        padding-left: 10px;
    }
}

// .fixed-side-nav.off .menu-link.home-link {
//     img.img-icon {
//         padding-left: 20px;
//     }
// }

@media (max-height:830px) {
    .fixed-side-nav .bd-links {
        min-height: 800px;
    }
}


@media (max-width:680px) {
    .cdk-overlay-pane {
        width: 100% !important;
        justify-content: flex-end !important;
    }

    .fixed-side-nav.off {
        // padding: 3px 15px
    }

    .fixed-side-nav {
        overflow-x: hidden;
        overflow-y: scroll;

        .menu-items-nav {
            margin-top: 20px;
        }
    }

    .pinned-sidebar .nav.inner-nav {
        margin-left: 0;
    }

    .main-container.full-with-left {
        margin-left: 0;
        padding-left: 0;
        width: 100%;
    }

    .sidebar-toggle-container {
        display: block;
        cursor: pointer;
    }

    .sidebar-toggler {
        display: none;
    }

    .fixed-side-nav.off {
        width: 0;
        min-width: 0;
        height: 30px;
        overflow: hidden;
    }

    .menu-link-text {
        font-size: 14px;
        padding-top: 0;
    }

    .bd-links .list-group-item {
        padding: 5px 15px;
    }

}

@media only screen and (max-device-width : 640px) {
    .bd-links .list-group-item {
        padding: 3px 15px;
    }
}

@media (max-width:768px) {
    .cdk-overlay-pane {
        width: 100% !important;
        justify-content: flex-end !important;
        min-width: 80vw;
    }
}