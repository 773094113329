.full-width {
    .mat-form-field, .mat-button, .mat-flat-button {
        width: 100%;
    }
}

.scroll-list {
    max-height: 300px;
    overflow-y: scroll;
}


.disable-header-steps {
    .mat-horizontal-stepper-header{
        pointer-events: none !important;
    }
}

.dialer-dialog {

    .mat-dialog-container {
        background: transparent;
        box-shadow: none;
    }
}

.sidebar-btn-tab .menu-trigger-btn {
    // background: #545454 !important;
}

.transparent-backdrop-container {
    .mat-drawer-backdrop.mat-drawer-shown {
        background-color: transparent !important;
    }
}

.thin-rows {
    .mat-list-base .mat-list-item {
        font-size: .85rem;
    }

    .mat-list-base .mat-list-item.mat-3-line {
        height: 50px !important;
    }

    .mat-list-base .mat-list-item .mat-list-icon {
        padding: 10px 2px !important;
    }
}

.mat-flat-button.auto-width {
    width: auto;
}

.boxed-select .mat-form-field-wrapper, .boxed-select-calendar .mat-form-field-wrapper {
    padding-bottom: 0!important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem!important;
}

.boxed-select .mat-form-field-underline, .boxed-select .mat-form-field-label-wrapper {
    display: none;
}

.game-bucket-selector-wrapper .mat-form-field-wrapper {
    padding-bottom: 0!important;
}

.skinny-paginator .mat-paginator-container {
    margin-top: -7px;
}

.skinny-paginator.no-margin .mat-paginator-container {
    margin-top: 0;
}

.borderless-tabs .mat-tab-label .mat-tab-label-content {
    font-size: 14px;
    font-weight: 600;
    color: #040404;
}

.user-search .mat-dialog-container {
    padding: 0;

    .mat-card-header-text {
        display: none;
    }
}

.resizable-dialog-panel .mat-dialog-container {
    padding: 0;
    resize: both;
    min-width: 350px;
    min-height: 200px;

    .mat-card-header-text {
        display: none;
    }
}

// Don't want height added to resizable-dialog-panel class or the height resize does not work properly.
.cdk-overlay-pane:not(.resizable-dialog-panel) .mat-dialog-container {
    height: auto !important;
}

.cdk-overlay-pane .history-menu-panel, .cdk-overlay-pane.history-menu-panel .mat-bottom-sheet-container {
    position: absolute !important;
    right: 0;
    top: 70px;
}

.cdk-overlay-backdrop.history-menu-backdrop {
    background: rgba(0, 0, 0, 0);
}

.hover-controller {
    .hover-only { display: none; }

    &:hover .hover-only { display: block; }
}


// .cdk-row .hover-only {
//     display: none;
// }
// .cdk-row:hover .hover-only {
//     display: block;
// }

.max-25 {
    max-height: 25px;
    overflow: hidden;
}


.history-menu-panel .mat-bottom-sheet-container {
    max-height: 80vh !important;
    min-width: 330px !important;
    // box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    width: calc(25% - 20px);
    overflow-x: hidden;
    min-height: calc(90vh - 80px);

    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.gray-btn {
    background-color: #848484 !important;
}

.mat-button-toggle {
    font-size: .85rem;

    button {
        color: #fff;
    }
}

.full-page-calendar {
    .mat-button-toggle.default {
        button {
            color: #000;
        }
    }
}

.auto-width {
    .mat-form-field-infix {
        width: auto !important;
        min-width: 175px;
    }
}

.event-inner-wrapper * {
    font-size: 0.85rem;
}

.shorter.mat-form-field.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 2px 0px 9px 0px;
}

.mat-drawer-container {
    min-height: 90vh;
}

.profile-container .mat-drawer-container {
    min-height: auto;
}

.mat-drawer-container.auto-height {
    min-height: auto;
}

.mat-drawer-container.no-min-height {
    min-height: auto;
}

.boxed-select.boxed-select-calendar .mat-form-field-underline, .boxed-select.boxed-select-calendar .mat-form-field-label-wrapper {
    display: block;
    background-color: transparent !important;
}

.boxed-select-calendar .mat-form-field-ripple {
    background-color: transparent !important;
}

.boxed-select .mat-form-field, .boxed-select-calendar .mat-form-field {
    width: 100%;
}

.boxed-select .mat-select-value, .boxed-select-calendar .mat-select-value {
    padding-left: 15px !important;
    padding-right: 15px !important;
    color: #888da8 !important;
}

.boxed-select .mat-form-field-infix, .boxed-select-calendar .mat-form-field-infix {
    border-top: 0 !important;
    padding: 12px 0 !important;
}

.boxed-select .mat-select-arrow-wrapper {
    padding-right: 10px !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(194, 112, 200, 0.1) !important;
}

.mat-select.hide-dropdown-arrow .mat-select-arrow-wrapper {
    visibility: hidden !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #f44336 !important;
}

.auto-tabs .mat-tab-label {
    min-width: auto !important;
}

.date-time-picker-dialog .mat-form-field.invalid-date-field.mat-form-field-appearance-outline {
    color: #f44336 !important;

    .mat-form-field-outline {
        color: #f44336 !important;
    }
}

.current-layout-container .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    opacity: unset;
    background: #fff;
    border-radius: 5px;
}

.mat-form-no-auto-padding  {
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
        transform: unset;
    }
}

.doc-form-wrapper .mat-form-no-auto-padding .mat-form-field.mat-form-field-invalid .mat-form-field-wrapper {
    padding-bottom: 1.34375em !important;
}

.mat-snack-bar-container {
    padding: 7px !important;
    margin: 5px !important;

    .message-icon {
        display: none !important;
    }
}

.mat-form-field-appearance-outline {
    .mat-form-field-flex {
        padding: 0 .75em 0 .75em;
        margin-top: -.25em;
    }

    .mat-form-field-infix {
        padding: 1em 0 !important;
        border-top: .84375em solid transparent !important;
    }
}

.avaliable-filter-form-field.mat-form-field-appearance-outline {
    &.with-white-bg {
        .mat-form-field-wrapper {
            background: #fff !important;
            border-radius: 5px !important;
        }
    }

    .mat-form-field-infix {
        padding: .7em 0 !important;
        border-top: 0.54375em solid transparent !important;
    }
}

.module-field-filter-form-field.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
        background: #fff !important;
        border-radius: 5px !important;
    }

    .mat-form-field-infix {
        padding: .7em 0 !important;
        border-top: 0.54375em solid transparent !important;
    }
}

.no-x-overflow .mat-tab-body-content {
    overflow-x: hidden;
}

.mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.84375em;
    margin-top: -.25em;
}



.shorter.mat-form-field.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 2px 0px 9px 0px;
}

.grid-page-wrapper .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 2px 0 9px 0;
}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: .25em;
}

.boxed-select span.mat-form-field-label-wrapper {
    top: -.84375em; 
    padding-top: .84375em;
}

.mat-form-field-placeholder, .mat-select-value {
    color: #818181 !important;
}

.mat-form-field-should-float .mat-form-field-placeholder {
    position: absolute;
    top: 20px;
    padding: 0 10px 0 10px;
    width: auto !important;
    font-size: 17px;
    background: #fff;
}

mat-form-field.mat-datepicker { position: relative !important; }
mat-form-field.mat-datepicker .mat-input-underline { position: absolute  !important; bottom: 0  !important; }

.mat-checkbox-layout {
    margin-bottom: 0 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #9525a6 !important;
}

.mat-nav-list .mat-list-item.is-active {
    background: #7774e7 !important;
}

.boxed-select .mat-select-value {
    padding-left: 0 !important;
}

.boxed-select-calendar .mat-select-value {
    padding-left: 15px !important;
}

.full-width .mat-form-field {
    width: 100%;
}

.mat-table .mat-header-row {
    background-color: #fff;

}

.mat-table .mat-header-cell {
    color: #7774e7;
    font-size: 13.5px;
    font-weight: 700;
    padding-left: 13px;
}

.mat-table.no-select-or-view-table .mat-header-cell {
    padding-left: 0;
}

.mat-table.no-select-or-view-table .mat-header-cell:first-of-type {
    padding-left: 24px;
}

.mat-table.no-select-field .mat-header-cell {
    padding-left: 24px;
}

.mat-table tbody td.mat-cell {
    font-size: 13.5px;
    margin: 0;
    padding: .5em 1em;
    color: #7a7676;
}

.mat-table .mat-checkbox-inner-container {
    height: 17px;
    width: 17px;
}

.mat-table .mat-column-select {
    text-align: center;
}

.mat-table tbody tr:nth-of-type(odd) {
    background: #f2f4f8 !important;
}

.dw-table td.mat-cell:first-child, td.mat-footer-cell:first-child, th.mat-header-cell:first-child {
    padding-left: 0 !important;
}

.dw-table td.mat-cell:last-child, td.mat-footer-cell:last-child, th.mat-header-cell:last-child {
    padding-right: 0 !important;
}

.dw-table .fa-angle-double-right {
    font-size: 25px !important;
    color: #7774e7 !important;
}

.centered .mat-paginator-container {
    justify-content: center !important;
}

.clear-btn {
    background: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    margin-left: -10px;
}

.centered, .filter-wrapper {
    text-align: center;
}

.mat-header-cell.centered {
    display: flex;
    justify-content: center;
}

.mat-drawer-container {
    height: 100%;
    background: transparent;
}


.custom-accordions .profile-custom-accordion .mat-expansion-panel {
    margin-bottom: 10px;
}

.mat-expansion-panel, .mat-accordion, .mat-expansion-panel-header-title {
    color: #888da8;
}

.fixed-side-nav .mat-expansion-indicator {
    margin-top: -10px;
}

.fixed-side-nav .mat-expanded .mat-expansion-indicator {
    margin-top: 0px;
}

.fixed-side-nav .mat-expansion-panel {
    box-shadow: none !important;

    .mat-expansion-panel-header {
        font-family: "AvenirLTPro-Book" !important;

        &:hover {
            .menu-link {
                color: #5d78ff;
            }            
        }
    }
}

.fixed-side-nav.off {
    .mat-expansion-panel-header {
        padding: 0 24px;

        .mat-content {
            overflow: visible;
        }
    }

    .mat-expansion-panel-body {
        display: none;
    }
    
    .mat-expansion-indicator {
        display: none;
    }
}

.fixed-side-nav.on {
    .mat-expansion-panel-header, .mat-expansion-panel-body {
        margin-left: -15px;
    }
    .mat-expansion-indicator {
        display: block;
    }

    .mat-expansion-panel-header .menu-link-icon {
        margin-right: 15px;
    }
}

.fixed-side-nav .mat-expansion-panel.mat-expanded {
    margin-top: -8px !important;
}

.fixed-side-nav .mat-expansion-panel-body {
    padding: 0 24px 0;
}


.stealth-notification {
    margin-top: -3px;
	background-color: rgb(241, 242, 240) !important;
	border-radius:5px;
	color: rgb(149, 149, 149);
    padding: 15px;
    border: 1px solid rgba(149, 149, 149) !important;
}

/* .mat-row, .mat-table .mat-header-row {
    min-width: 725px;
} */

.cdk-overlay-pane.chat-conversation-mat-dialog-panel {
    // width: unset !important;
    // height: unset !important;
    // max-width: 100vw; 
    // max-height: 100vh;
}

.cdk-overlay-pane.chat-conversation-mat-dialog-panel .mat-dialog-container {
    // resize: both;
    // width: unset !important;
    // height: unset !important;
    // max-width: 100%; 
    // max-height: 100%;
    // overflow: auto;
    background: #fff;
    padding: 0;
}

.cdk-overlay-pane.credit-notification-dialog-panel .mat-dialog-container {
    box-shadow: none;
    background: transparent;
    padding: 0;
}

.cdk-overlay-pane.schedule-updater .mat-dialog-container {
    min-width: 1060px;
}

.voiceshot-panel {
    min-width: 500px;
    min-height: 70%;
}

.global-dashboard .mat-expansion-panel-spacing {
    margin: 5px 0;
}

.no-margin .mat-expansion-panel-spacing {
    margin: 0;
}

.dashboard-accordion .mat-expansion-panel-header.auto-height {
    height: auto !important;
    padding-top: 10px;
    padding-bottom: 10px;
}


// .mat-menu-panel.nav-menu { margin-top: 20px; }

.mat-menu-panel.admin-menu-wrapper {
    overflow: visible;
    /* margin-left: -44px; */
    margin-left: -50px;
    max-width: 320px;
}

.mat-menu-content {
	position: relative;
}

.mat-menu-panel.notifications-menu-wrapper {
    max-width: 320px;
    width: 285px;
}

.mat-menu-panel.auto-width {
    max-width: unset;
    width: auto;
}

.profile-wrapper .mat-list-base .mat-list-item {
    border-top: 1px solid rgba(0,0,0,.12);
    cursor: pointer;
}

.profile-wrapper .mat-list-base .mat-list-item:hover {
    background-color: rgba(0,0,0,.05);
}

.full-width-backdrop {
    max-width: 100vw;
}

.cdk-overlay-backdrop {
    background: rgba(0,0,0,.32);
}

.large-mat-icon .material-icons {
    font-size: 40px;
}

.dropdown-menu.show, .cdk-overlay-container {
    z-index: 9999 !important;
}

.remove-margin.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;
}

.no-bottom-padding.mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.skills-panels .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0 10px !important;
}

.cover-btn .mat-raised-button.mat-primary, .cover-btn .mat-raised-button.mat-warn {
    padding: 4px 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 11px;
    border: 1px solid #fff;
}

.cover-btn .mat-raised-button.mat-primary .inner-btn-text {
    display: none;
}

.mat-drawer-inner-container {
    overflow: scroll;
}


.mat-option-text .flex {
    display: flex;
}

.mat-option-text .flex .icon-wrapper {
    width: 25px;
    display: flex;
    align-self: center;
}

.mat-option-text .flex .label-wrapper {
    padding-left: 5px;
}

.icon-only-mobile .inner-btn-text {
    display: none;
}


.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.notifications-menu,
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.admin-menu {
    opacity: 0;
}

/* .sort-wrapper.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
    height: 35px;
}
 */
.mobile-sort-tools {
    display: none;
    padding: 0 5px;
}

.mobile-sort-tools .sort-direction {
    display: flex;
    align-self: center;
}

/* Placeholder overrides */
.grid-inputs {
    input.mat-input-element, .mat-select-placeholder {
        font-size: 14px;
    }

    .mat-form-field-prefix .mat-icon, .mat-form-field-suffix .mat-icon {
        font-size: 22px;
    }
}

.mobile-sort-tools .sort-direction .mat-radio-button {
    padding: 0 10px;
}

.no-margin-outer-wrapper .mat-form-field-appearance-outline .mat-form-field-wrapper, 
.no-margin-wrapper .mat-form-field-appearance-outline .mat-form-field-wrapper,
.no-margin-wrapper.mat-form-field-appearance-outline .mat-form-field-wrapper, 
.no-margin-wrapper .mat-form-field-appearance-outline .mat-form-field-wrapper,
.no-margin-wrapper.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 5px;
}

.no-padding-outer-wrapper .mat-form-field-appearance-outline .mat-form-field-infix,
.no-padding-wrapper.mat-form-field-appearance-outline .mat-form-field-infix, 
.no-padding-wrapper.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: .5em 0 !important;
}

.no-margin-wrapper.no-pad.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-infix, 
.no-margin-wrapper.no-pad.mat-form-field-appearance-legacy .mat-form-field-wrapper .mat-form-field-infix {
    padding: 5px !important;
}

.no-input-line.mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
}

.white-form-bg.mat-form-field-appearance-outline .mat-form-field-outline {
    background: #fff;
}

.mat-tab-link.fifty-fifty {
    min-width: 50%;
}

.small-field .mat-form-field-infix {
    width: 75px;
}

.cdk-overlay-container .cdk-global-overlay-wrapper {
    // justify-content: center !important;
}

.custom-bottom-border .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(255,255,255,0.7);
    height: 2px;
}

.custom-bottom-border .mat-form-field {
    width: 100%;
}

.custom-bottom-border .mat-form-field-flex, .custom-bottom-border .mat-form-field-appearance-legacy .mat-form-field-label {
    color: #fff;
    font-size: 17px;
}

.agree-terms .mat-checkbox-frame {
    border-color: #fff;
}


button.blue-accent {
    background-color: #17a2b8;
    color: #fff;
}

.dw-rules.mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding: 0;
    margin: 0;
}

.md-18 .material-icons { font-size: 18px; }
.md-24 .material-icons { font-size: 24px; }
.md-36 .material-icons{ font-size: 36px; }
.md-48 .material-icons { font-size: 48px; }

.mat-tab-label:hover {
    background-color: #e4e4e4 !important;
}

.mat-card-actions.no-margin-tucking {
    margin: 0;
}

.loading-shaded-spinner.make-highest-index {
    z-index: 100000;
}

@media (min-width:481px)  {

    .icon-only-mobile .inner-btn-text {
        display: block;
    }    

    .cover-btn .mat-raised-button.mat-primary, .cover-btn .mat-raised-button.mat-warn {
        font-size: 14px;
    }
    
    .cover-btn .mat-raised-button.mat-primary .inner-btn-text {
        display: block;
    }
}

.mat-table.dw-mobile-table {
    max-height: unset !important;
}


// .dw-mobile-table .mat-row:nth-child(even) {background: #f2f4f8}
// .dw-mobile-table .mat-row:nth-child(odd), .dw-mobile-table.mat-table tbody tr:nth-of-type(odd) {background: #fff !important;}
// .dw-mobile-table .mat-row:nth-child(odd) button, .dw-mobile-table.mat-table tbody tr:nth-of-type(odd) button {border: 1px solid #d5d6da !important;}


.dw-mobile-table .mat-row {
    background: #fff;

    &:hover {
        background: #f2f4f8
    }
}

.dw-mobile-table .mat-cell {
    color: #7a7676 !important;
}


button.square-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    min-width: 55px;    
}

button.square-icon-sm {
    padding: 0;
    min-width: 40px;  
}

.action-col.vertical-align {
    margin-top: -8px;
}


.non-fucked-width .mat-form-field-infix {
    width: auto;
    min-width: 75px;
}


.mat-flat-button.mat-primary, .mat-raised-button.mat-primary {
    color: #fff !important;
}


.mat-tab-group, 
.mat-paginator, .mat-paginator-page-size .mat-select-trigger,
.mat-button, .mat-raised-button, .mat-icon-button, 
.mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab, .mat-table {
    font-family: 'AvenirLTPro-Book' !important;
}

.mat-tab-group.single-tab .mat-tab-header {
    display: none;
}

.mat-menu-item {
    display: flex !important;
    align-items: center;
}

.justify-right {
    display: flex;
    justify-content: flex-end;
}


.mat-table.no-header-cell .mat-header-row {
    display: none;
}

.email-inline {
    .mat-paginator-range-label {
        margin: 0 15px 0 15px;
        color: #B5B5C3;
        font-weight: 600;
    }

    button.mat-icon-button {
        border-radius: 5px;
        background: #F3F6F9;
        border-color: #F3F6F9;
        height: calc(1.25em + 1.1rem + 2px);
        width: calc(1.25em + 1.1rem + 2px);
        font-size: 0.75rem !important;
        display: flex; align-items: center; justify-content: center;
        margin: 0 5px;

        .mat-paginator-icon {
            width: 23px;
            cursor: pointer;

            &:hover {
                color: #8950FC;
            }
        }
    }
}

.white-bg.mat-drawer-container {
    background: transparent !important;
}

.icon-35 .material-icons {
    font-size: 35px;
}


.mat-progress-bar .mat-progress-bar-fill {
    border-radius: 0.28rem;
}


.bordered-body {
    .mat-expansion-panel-body {
        border: 1px solid #00000012;
        border-top: 0;
        padding: 9px 24px 16px;
    }
}

.auto-width {
    .mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
        min-width: auto;
    }
}

mat-row, mat-footer-row {
    min-height: 35px !important;
}

mat-radio-button.border.border-secondary {   
    border-color: red; 

    .mat-radio-inner-circle {
        background-color: #212121;
    }
}

.row.user-defined-search-row {
    mat-form-field {
        width: 100%;

        .mat-form-field-wrapper {
            padding-bottom: 0 !important;
        }
    }
}

.upload-queue-wrapper {
    field-type-edit, 
    .flex-wrapper.form-field {
        width: 100%;
    }

    .flex-wrapper .column {
        padding: 0 !important;
    }

    mat-form-field {
        width: 100%;

        .mat-form-field-wrapper {
            padding-bottom: 0 !important;
        }
    }
}

.light .mat-form-field-appearance-legacy.sort-wrapper.grid-inputs.mat-form-field-disabled .mat-form-field-underline {
    background-image: unset !important;
    background-size: unset !important;
    background-repeat: unset !important;
    background-position: unset !important;
    background-color: rgba(0, 0, 0, 0.42) !important;
}

.filter-date-field .mat-select div.mat-select-arrow-wrapper .mat-select-arrow {
        border: none !important;
}

.mat-form-field.make-infix-flex {
    .mat-form-field-infix {
        display: flex !important;
    }
}

.no-max-width.mat-menu-panel {
    max-width: unset;
}

.no-pad-mar-inflix-mat-field.mat-form-field {
    margin-bottom: 0;

    .mat-form-field-wrapper {
        padding-bottom: 0 !important;
    }

    .mat-form-field-infix {
        width: auto !important;
    }
}

/* Opening mat-calendar then ngx-mat-datetime-picker caused styling issues. These styles fix it */
.mat-calendar-body-cell {
    position: absolute; // necessary for working in Firefox
}

ngx-mat-datetime-content {
    .mat-calendar-arrow {
        border: none !important;
        width: 10px !important;
        height: 5px !important;
    }
    ngx-mat-calendar-header .mat-calendar-arrow {
        border-left: 5px solid transparent !important;
        border-right: 5px solid transparent !important;
        border-top-width: 5px !important;
        border-top-style: solid !important;
        width: 0 !important;
        height: 0 !important;
    }
    ngx-mat-month-view .mat-calendar-body-cell {
        position: relative !important;
    }
    ngx-mat-timepicker {
        input.mat-input-element {
            cursor: default;
        }
        .mat-form-field-underline,
        .mat-form-field-subscript-wrapper,
        .mat-form-field-label-wrapper {
            display: none;
        }
        .mat-form-field-appearance-legacy .mat-form-field-infix {
            padding: 0;
            border-top: 0.2rem solid white;
        }
        .mat-form-field {
            line-height: 1rem;
        }
        .mat-form-field-wrapper {
            padding-bottom: 0 !important;
        }
    }
}
/* Ending ngx-mat-datetime-picker styles fix */

/* Mobile table overrides */
@media only screen and (max-width: 680px) {

    .mobile-sort-tools {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-left: 15px;
    }

    .mobile-sort-tools.custom-grid-mobile-sort-tools {
        .sort-field {
            min-width: 100px;

            mat-form-field.mat-form-field {
                width: 100% !important;

                .mat-form-field-infix {
                    padding: .6em 0 !important;
                    border-top: 0.24375em solid transparent !important;
                }
            }
        }
    }

    .mat-select-panel-wrap {
        flex-basis: 87% !important;
    }

    .header-filter, .dw-mobile-table .hide-on-mobile {
        display: none !important;
    }

    .hide-from-mobile-view {
        display: none !important;
    }

    .header-btns .btn.pull-right {
        width: auto !important;
    }

    .dw-mobile-table tr.mat-footer-row, .dw-mobile-table tr.mat-row {
        height: auto;
    }

    .dw-mobile-table .mat-row, .dw-mobile-table .mat-table .mat-header-row,
    .dw-mobile-table tr.mat-row, .dw-mobile-table table.mat-table tr.mat-header-row {
        min-width: auto;
    }

    .dw-mobile-table mat-cell:first-of-type, .dw-mobile-table mat-footer-cell:first-of-type, .dw-mobile-table mat-header-cell:first-of-type,
    .dw-mobile-table td.mat-cell:first-of-type, .dw-mobile-table td.mat-footer-cell:first-of-type, .dw-mobile-table td.mat-header-cell:first-of-type {
        padding-left: 15px;
    }

    .dw-mobile-table.no-select-or-view-table mat-cell:first-of-type, .dw-mobile-table.no-select-or-view-table mat-footer-cell:first-of-type, .dw-mobile-table.no-select-or-view-table mat-header-cell:first-of-type,
    .dw-mobile-table.no-select-or-view-table td.mat-cell:first-of-type, .dw-mobile-table.no-select-or-view-table td.mat-footer-cell:first-of-type, .dw-mobile-table.no-select-or-view-table td.mat-header-cell:first-of-type {
        padding-left: 0;
    }

    .dw-mobile-table.no-select-field mat-cell:first-of-type, .dw-mobile-table.no-select-field mat-footer-cell:first-of-type, .dw-mobile-table.no-select-field mat-header-cell:first-of-type,
    .dw-mobile-table.no-select-field td.mat-cell:first-of-type, .dw-mobile-table.no-select-field td.mat-footer-cell:first-of-type, .dw-mobile-table.no-select-field td.mat-header-cell:first-of-type {
        padding-left: 0;
    }

    .dw-mobile-table mat-cell:last-of-type, .dw-mobile-table mat-footer-cell:last-of-type, .dw-mobile-table mat-header-cell:last-of-type,
    .dw-mobile-table td.mat-cell:last-of-type, .dw-mobile-table td.mat-footer-cell:last-of-type, .dw-mobile-table td.mat-header-cell:last-of-type {
        padding-right: 15px;
    }

    .dw-mobile-table .mat-header-row {
        display: none;
    }

    .dw-mobile-table mat-footer-row, .dw-mobile-table mat-row,
    .dw-mobile-table tr.mat-footer-row, .dw-mobile-table tr.mat-row {
        min-height: 35px;
    }

    .dw-mobile-table .mat-row {
        display: block;
        padding: 10px;
    }

    .dw-mobile-table .mat-cell {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        min-height: 15px;
        border: 0;
    }

    .dw-mobile-table.mat-table, .smart-table-wrapper, .mat-table-wrapper {
        overflow: hidden !important;
    }

    .dw-mobile-table .mat-cell[data-th]:before {
        content: attr(data-th);
        color: #7774e7;
        font-size: 14px;
        font-weight: 700;
    }

    .dw-mobile-table mat-footer-row::after, .dw-mobile-table mat-header-row::after, .dw-mobile-table mat-row::after,
    .dw-mobile-table tr.mat-footer-row::after, .dw-mobile-table tr.mat-header-row::after, .dw-mobile-table tr.mat-row::after {
        display: none;
    }

    .mobile-sort-tools .mat-form-field-infix {
        width: 75px;
    }
}


@media (max-width:680px)  {
    .mat-menu-panel.admin-menu-wrapper {
        margin-left: auto;
    }

    // .report-wizard-stepper-wrapper .mat-horizontal-content-container {
    //     padding: 0 10px 24px 10px !important;
    // }
}

/* Nav Web/Mobile Styles */
.control-block {
    &.mobile-nav-controls {
        display: flex;
    }

    &.web-nav-controls {
        display: none;
    }
}

.web-menu-control-trigger {
    display: none !important;
}

.mobile-menu-control-trigger {
    display: block !important;
}

.web-sub-menu-title {
    display: none;
}

.nav-menu.mobile-nav-controls .mat-menu-item .mat-menu-item {
    margin-left: -16px !important;
}

@media (min-width: 770px) {
    .control-block {
        &.mobile-nav-controls {
            display: none;
        }

        &.web-nav-controls {
            display: flex;
            justify-content: flex-end;
        }
    }

    .web-menu-control-trigger {
        display: block !important;
    }

    .mobile-menu-control-trigger {
        display: none !important;
    }

    .web-sub-menu-title {
        display: block;
    }
}

.time-off-form-timepicker {
    z-index: 9999 !important;
}

.timepicker-overlay, .timepicker {
    z-index: 9999 !important;
}

.dependent-field-value-wrapper .dependent-value-field .grid-field-switch {
    .flex-wrapper .field.column {
        width: 100%;
        max-width: 100% !important;
        padding: 0.2rem;

        .mat-form-field {
            font-size: 15px !important;
            width: 100%;
            max-width: 100% !important;

            .mat-form-field-wrapper {
                width: 100% !important;
                max-width: 100% !important;

                .mat-form-field-infix {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }

        .popup-selector-wrapper {
            &.default-flex {
                width: 100% !important;
                flex-wrap: wrap !important;
                padding-bottom: 1.34375em !important;
                box-sizing: border-box;

                .relate-input {
                    width: calc(100% - 99px) !important;
                    min-width: 100px !important;
                    min-height: 60px !important;
                    font-size: 15px !important;
                }
            }
        }
    }

    .flex-wrapper.check-field {
        width: 100%;
        max-width: 100% !important;
        padding: 0.2rem !important;

        .search-checkbox {
            width: 100%;

            .mat-form-field {
                font-size: 15px !important;
                width: 100%;
                max-width: 100% !important;

                .mat-form-field-wrapper {
                    width: 100% !important;
                    max-width: 100% !important;

                    .mat-form-field-infix {
                        width: 100% !important;
                        max-width: 100% !important;
                    }
                }
            }
        }
    }
}